<template>
    <authorize-layout>
        <v-card
            class="mx-auto my-auto pa-5"
            style="border-radius: 25px;"
            width="560"
            height="483"
            outlined
            elevation="5"
        >
            <v-card-title class="justify-center auth-title">Reset password</v-card-title>
            <v-card-text class="mb-15">
                <v-form class="pa-5">
                    <v-row>
                        <v-col>
                            <v-text-field :error-messages="errors.email" label="email" v-model="email"/>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <p class="text-style reset-text">Enter your email and we will help you reset your password</p>
                        </v-col>
                    </v-row>

                </v-form>
            </v-card-text>
            <v-card-actions class="text-center justify-space-around">
                <v-btn
                    class="auth-title auth-btn"
                    :disabled="!email"
                    @click="handleForgotPassword"
                >
                    Send
                </v-btn>
            </v-card-actions>
        </v-card>
    </authorize-layout>
</template>

<script>
    import {mapActions, mapMutations} from "vuex";
    import AuthorizeLayout from '../layouts/AuthorizeLayout.vue';
    import Api from "../api/Api";

    export default {
        name: "ForgotPassword",
        components: {AuthorizeLayout},
        data: function () {
            return {
                email: '',
                errors: {}
            }
        },
        methods: {
            async handleForgotPassword() {
                const {email} = this;
                let response = await Api.forgotPassword(email).catch(e => this.errors = e.response.data.errors)
                if (response.status === 200) {
                    this.errors = {}
                    this.showSnackBar({color: 'success', timeout: 3000, text: 'Password Reset Confirmed'})
                }
            },
            ...mapActions(['signUp']),
            ...mapMutations(['showSnackBar']),
        },
    }
</script>

<style scoped lang="scss">

.row {
    margin: 0 !important;
}

.col {
    padding: 0 !important;
}

.reset-text {
    font-size: 14px !important;
    line-height: 17px !important;
}

</style>
